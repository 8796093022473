@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(../font/Lato-Light.bfc37ff1d5543ecd4ec6.ttf) format('truetype');
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(../font/Lato-Regular.9919edff6283018571ad.ttf) format('truetype');
}

@font-face {
    font-family: 'Lato';
    src: url(../font/Lato-Italic.0b4ed00316a7940bfc3f.ttf) format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(../font/Lato-Bold.5dd5aa0269395f0ea927.ttf) format('truetype');
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(../font/Lato-Black.0b51cf32bbc2d8b8d840.ttf) format('truetype');
}

html {
    background-color: #FFFFFF;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}
:root {
    --text-primary-color: #5A5076;
    --heding-primary-color: #2E2056;
    --bg-primary-color: #8140EA;
    --link-primary-color: #72DBF6;
    --button-primary-color: #4EEFA4;
}

.container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1149px;
}

body {
    font-family: 'Lato', sans-serif;
}

h1, h2 {
    font-style: normal;
    font-weight: 900;
}

h1 {
    font-size: 64px;
    margin-bottom: 14px;
}

header h2 {
    color: white;
}

h2 {
    font-size: 42px;
    color: #261747;
}

.d-none {
    display: none;
}

.text-center {
    text-align: center;
}

.m-center {
    margin: 0 auto;
}

a {
    text-decoration: none;
    color: var(--link-primary-color);
}


/* body */


/* Banner */
.banner{
    background: #FFFFFF;
    box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
    border-radius: 0px 0px 10px 10px;
    padding: 10px 20px 10px 10px;
    display: grid;
    gap: 30px;
    align-items: center; 
    grid-template-columns: 0.8fr 2fr 0.7fr;
    margin-bottom: 24px;
}
.banner .logo-box{
    background: #EAF2F8;
    border-radius: 10px;
}
.banner .logo-box a{
    background: url(../image/domainer_logo.svg);
    background-repeat: no-repeat;
    background-position: center center;
    color: transparent;
    display: block;
    font-size: 0;
    padding: 20px 30px;
    height: 138px;
}
.banner .title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 60px;
    color: #090909;
}
.banner .info{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #7D7F82;
    position: relative;
    margin-left: 15px;
    background-color: transparent;
}
.banner .info:before{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 6px;
    height: 100%;
    margin-left: -15px;
    border-radius: 10px;
    background: #F27558;
}
.banner .domaner-btn{
    background: #F27558;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    padding: 23px 5px;
    display: block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #000000;
}
.banner .domaner-btn:hover {
    background-color: rgb(249, 173, 141);
}
@media only screen and (max-width: 1090px) {
    .banner .title{
        font-size: 22px;
        line-height: 50px;
        margin-bottom: 10px;
    }
    .banner .info{
        font-size: 18px;
    }
    .banner .domaner-btn{
        font-size: 18px;
    }
    .banner .domaner-btn {
        font-size: 18px;
        line-height: 22px;
        display: table;
        margin: 0 auto;
        padding: 23px 30px;
    }
}
  @media only screen and (max-width: 940px) {
    .banner{
        grid-template-columns: 1fr;
        padding: 10px;
    }
    .banner .logo-box a{
        background: url(../image/domainer_logo_mob.svg);
        background-repeat: no-repeat;
        background-position: center center;
        height: 66px;
    }
    .banner .title{
        line-height: 24px;
        text-align: center;
    }
    .banner .info{
        font-size: 16px;
        line-height: 20px;
        text-align: center;
    }
    .banner .info:before{
        content: none;
    }
  }
  @media only screen and (max-width: 440px) {
    .banner .domaner-btn {
        display: block;
        padding: 23px 0px;
    }
  }


.site-header {
    width: 100%;
    background: #271747;
    background-image: url(../image/header-overlay.webp);
    background-repeat: no-repeat;
    color: white;
}

.logo-wrapper {
    margin-bottom: 14px;
}

header .site-logo, 
footer .site-logo {
    display: block;
    width: 236px;
    height: 35px;
}

header .site-logo {
    background-image: url(../image/logo.png);
}

footer .site-logo {
    background-image: url(../image/logo-black.png);
}

footer .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #828C98;
}

header {
    /* padding-top: 24px; */
}

header .subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 42px;
    margin-bottom: 52px;
}

header .second-subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    margin-bottom: 24px;
}

.double-arrow {
    width: 16.51px;
    height: 23px;
    background-image: url(../image/double-arrow.png);
    margin-bottom: 24px;
    margin-top: 35px;
    animation: arrow-move 0.5s linear infinite;
}

.double-arrow:hover {
    cursor: pointer;
}

.sub-title {
    padding: 0 100px;
}

/* Home table styles ↓↓↓ */

.t-section {
    padding: 15px;
    margin: 30px 0;
}

.home-table {
    width: 100%;
    border: 0;
    border-collapse: separate;
    border-spacing: 0 10px;
    margin-top: -10px;
    font-size: 14px;
    color: #2E2056;
}

.home-table thead tr {
    display: none;
}

.home-table td {
    border: solid 1px #000;
    border-style: solid none;
    padding: 19px;
    background-color: #F9FBFF;
    max-width: 250px;
    position: relative;
}

.home-table td:first-child {
    border-left-style: solid;
    border-top-left-radius: 70px;
    border-bottom-left-radius: 70px;
    position: relative;
}

.home-table td:last-child {
    border-right-style: solid;
    border-bottom-right-radius: 70px;
    border-top-right-radius:70px;
}

.home-table td:first-child .number {
    position: absolute;
    width: 24px;
    height: 24px;
    line-height: 24px;
    font-weight: bold;
    font-size: 15px;
    color:white;
    background-color: #2FD7FC;
    border-radius: 12px;
    text-align: center;
    top: 20px;
    left: 20px;
}

.home-table li {
    margin-left: 20px;
}

.home-table .t-logo:hover,
.home-table .t-site-link:hover {
    cursor: pointer;
}

.home-table tr .t-logo {
    background-color: #222222;
}

.home-table tr .t-site-link {
    background-color: #23CFA6;
}

.home-table tr:hover .t-logo,
.home-table tr:hover .t-site-link {
    background-color: #6165D9;
}

.home-table tr:hover td {
    border: solid 1px #6165D9;
    border-style: solid none;
}

.home-table .t-site-link {
    width: 181px;
    height: 56px;
    line-height: 56px;
}

.home-table .t-logo {
    height: 80px;
    line-height: 80px;
    width: 183px;
}

.home-table .t-logo, 
.home-table .t-site-link {
    display: block;
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    text-align: center;
    border-radius: 70px;
    color: #FFFFFF;
}

.home-table .col-title,
.home-table .col-value {
    font-size: 14px;
} 

.home-table .col-title {
    text-transform: uppercase;
    color: #B1B1B1;
    margin-bottom: 10px;
}

.home-table .col-value {
    color: #261747;
    font-weight: bold;
}

.block {
    margin-top: 82px;
    margin-bottom: 38px;
    padding-top: 47px;
    padding-left: 52px;
    padding-right: 52px;
    padding-bottom: 65px;
    background-color: #FFFFFF;
    border-radius: 20px;
    box-shadow: 11px 14px 60px rgba(46, 92, 118, 0.2);
}

.block .title {
    color: #261747;
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    margin-bottom: 58px;
}

.block .content {
    padding-bottom: 28px;
}

.block .content p {
    font-size: 17px;
    line-height: 30px;
    color: #63587B;
    margin-bottom: 35px;
}

.block .footer .images {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer .images img {
    border-radius: 8%;
}

.area-2 {
    background-color:#EFECF5;
}

.block .header {
    display: flex;
}

.block .header .title {
    font-style: normal;
    font-weight: 900;
    font-size: 26px;
    color: #261747;
    margin-left: 15px;
    margin-bottom: 30px;
    height: 45px;
    line-height: 45px;
}

.block .header .img {
    width: 45px;
    height: 45px;
    background-repeat: no-repeat;
    background-position: center;
}

.area-2 {
    padding-top: 99px;
    padding-bottom: 84px;
}

.area-2 .block {
    padding-bottom: 0px; 
}
.area-2 h2{
    padding: 0 15px;
}

.area-3 {
    padding-top: 99px;
    padding-bottom: 10px
}

.area-3 .block {
    padding-bottom: 0px;

}

.area-2 .block > div:nth-of-type(1) .header .img {
    background-image: url(../image/globe.png);
}

.area-2 .block > div:nth-of-type(2) .header .img {
    background-image: url(../image/book.png);
}

.area-2 .block > div:nth-of-type(3) .header .img {
    background-image: url(../image/flag.png);
}


.area-3 .block > div:nth-of-type(1) .header .img {
    background-image: url(../image/browser.png);
}

.area-3 .block > div:nth-of-type(2) .header .img {
    background-image: url(../image/wallet.png);
}

.area-3 .block > div:nth-of-type(3) .header .img {
    background-image: url(../image/present.png);
}

/* FAQ styles ↓↓↓ */

.faq-section-wrapper {
    padding-top: 42px;
}

.faq-wrapper {
    padding-top: 58px;
}

.faq-heading {
    margin-bottom: 38px !important;
    padding-top: 68px !important;
}

.faq:not(:first-of-type) {
    border-top: 1px solid #E5EFF9;
}

.faq.expanded+.faq {
    border-top: 0px;
}

.faq-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faq-title h3 {
    color: #261747;
    font-weight: normal;
    font-size: 28px;
    height: 98px;
    line-height: 98px;
    pointer-events: none;
}

.faq-btn {
    width: 49px;
    height: 49px;
    border: 0;
    border-radius: 25px;
    background: rgb(246,246,246);
    background: linear-gradient(342deg, rgba(246,246,246,1) 42%, rgba(233,241,249,1) 69%);
    margin-right: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 42px;
}

.faq.expanded .faq-btn {
    background: linear-gradient(342deg, rgba(233,241,249,1) 42%, rgba(246,246,246,1) 69%);
}

.faq-btn:hover, .faq-title:hover {
    cursor: pointer;
}

.faq-btn:hover {
    background-color: #e9eef5;
    border-radius: 25px;
    transition: background-color 0.2s ease;
}

.faq {
    padding-left: 56px;
    transition: background-color 0.2s linear, font-weight 0.2s linear, border-radius 0.2s linear;
}

.faq .faq-body {
    font-size: 17px;
    line-height: 24px;
    color: #404447;
    padding-right: 142px;
    padding-bottom: 44px;
}

.faq.collapsed .faq-body {
    display: none;
}

.faq.expanded {
    background-color: rgba(229, 239, 249, 0.8);
    border-radius: 15px;
}

.faq-btn span {
    pointer-events: none;
}

.faq.collapsed .faq-btn span {
    color: #23CFA6;
 }

.faq.expanded .faq-btn span {
    color: black;
}

.faq.expanded .faq-btn span.minus,
.faq.collapsed .faq-btn span.plus {
    display: block;
}

.faq.collapsed .faq-btn span.minus,
.faq.expanded .faq-btn span.plus {
    display: none;
}

.faq.expanded .faq-title h3 {
    font-weight: 900;
}

.container.bottom {
    color: white;
}

.container.bottom ul {
    list-style: none;
    margin-left: 36px;
}

.container.bottom .block {
    background-color: #0D1C2D;
    padding-bottom: 15px;
}
.container.bottom .block .title {
    margin-bottom: 40px;
    color: white;
}

.container.bottom ul li:before {
    content: "\2022";  
    color: #6165D9; 
    font-weight: bold; 
    font-size: 12px;
    display: inline-block; 
    width: 3em; 
    margin-left: -3em; 
}

.container.bottom ul li {
    margin-bottom: 28px;
    font-size: 18px;
    line-height: 24px;
}

.m-visible {
    display: none;
}

.m-hidden {
    display: initial;
}

@media only screen and (max-width: 768px) {

    h1 {
        font-size: 38px;
    }

    h2 {
        font-size: 26px;
    }
    
    header .subtitle {
        font-size: 18px;
    }

    .mobile-detector {
        display: none;
    }

    .m-visible {
        display: initial;
    }
    
    .m-hidden {
        display: none;
    }

    .m-slide-down {
        -moz-transition: height .5s;
        -ms-transition: height .5s;
        -o-transition: height .5s;
        -webkit-transition: height .5s;
        transition: height .5s;
        height: 0;
        overflow: hidden;
    }

    .home-table tr {
        display: table;
        background: #f9fbff;
        border-radius: 50px;
        max-width: -webkit-fill-available;
        width: 100%;
        padding: 10px 16px;
        margin-bottom: 16px;
    }
    .home-table tr:hover td {
        border: none;
        border-style: none;
    }

    .home-table td {
        display: inline-table;
        border: none;
        padding: 0;
    }

    .home-table td:nth-of-type(1),
    .home-table td:nth-of-type(6) {
        width: 100%;
        max-width: 450px;
        border: none;
        display: block;
        margin: 0px auto;
        border-radius: 50px;
    }

    .home-table td:nth-of-type(1) {
        padding-bottom: 6px;
    }

    .home-table td:nth-of-type(6) {
        padding-top: 6px;
    }

    .home-table td:nth-of-type(2),
    .home-table td:nth-of-type(3),
    .home-table td:nth-of-type(4),
    .home-table td:nth-of-type(5) {
        width: 48%;
        padding-left: 8%;
    }

    .home-table td:first-child .number {
        top: 0;
        left: 0;
    }

    .home-table .t-logo,
    .home-table .t-site-link {
        height: 64px;
        line-height: 64px;
    }

    .home-table .t-logo {
        width: 100%;
        background-color: #222222 !important;
    }

    .home-table .t-site-link {
        background-color: #23CFA6 !important;
    }

    .home-table .t-site-link {
        width: 90%;
        margin: 0 auto;
    }

    .block {
        margin-top: 26px;
        margin-left: 22px;
        margin-right: 22px;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 18px;
    }

    .block .title {
        margin-bottom: 32px;
    }

    .block .header .title {
        height: auto;
        line-height: initial;
    }   

    .block .header .img {
        flex-shrink: 0;
    }

    .block .footer .images {
        padding-left: 24px;
        position: relative;
        height: 215px;
    }

    .block .footer img {
        width: 274px;
        margin-left: 0px !important;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    /* Mobile read more slide area ↓↓↓ */

    .m-readmore-btn {
        color: #2E2056;
        height: 60px;
        width: 100%;
        border-radius: 20px;
        border: 1px solid #2E2056;
        background-color: transparent;
        text-align: center;
        font-weight: 900;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 18px;
    }

    .m-readmore-btn:active {
        background-color: #fff0e6;
    }

    .m-readmore-btn .img {
        background-image: url(../image/arrow.png);
        width: 16px;
        height: 18px;
        margin-left: 18px;
    }

    .m-readmore-btn span {
        pointer-events: none;
    }

    .m-slider-nav {
        width: 100px;
        margin: 12px auto 0px;
        padding-bottom: 20px;
    }

    .m-slider-nav ul {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: nowrap;
    }

    .m-slider-nav ul li {
        list-style-type: none;
        width: 7px;
        height: 7px;
        background-color: #DEDDE2;
        border-radius: 12px;
    }

    .m-slider-nav ul li.active {
        background-color: #2E2056;
    }

    .m-slider-img {
        height: 0;
        opacity: 0;
        transition: opacity 1s ease-out;
    }

    .m-slider-img.active {
        opacity: 1;
        height: auto;
    }

    .area-2 {
        padding-top: 42px;
        padding-bottom: 10px;
    }

    .area-3 {
        padding-top: 42px;
    }

    .faq-section-wrapper {
        padding-top: 18px;
    }

    .faq-wrapper {
        padding-left: 22px;
        padding-right: 22px;
        padding-top: 18px;
    }

    .faq {
        padding-left: 22px;
    }

    .faq-title {
        padding-bottom: 12px;
        padding-top: 10px;
    }

    .faq-title h3 {
        font-size: 20px;
        line-height: 38px;
        padding-top: 10px;
        padding-right: 10px;
        height: auto;
    }

    .faq .faq-body {
        padding-right: 22px;
    }

    .faq-btn {
        flex-shrink: 0;
        margin-right: 12px;
    }

    footer .container {
        display: block;
        text-align: center;
        padding-bottom: 20px;
    }
}


@keyframes arrow-move {
    0% {transform: translateY(0px)}
    50% {transform: translateY(-5px)}
    100% {transform: translateY(0px)}
}


